import React, { useState } from 'react'
import Gallery from 'react-photo-gallery'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'

const normalizeClientsArray = (inputArray) =>
  inputArray.map((item) => ({
    title: item.title ? item.title.toUpperCase() : '',
    alt: item.alt ? item.alt.toLowerCase() : 'Logo of client',
    src: `https:${item.image.file.url}`,
    height: item.image.file.details.image.height,
    width: item.image.file.details.image.width,
  }))

export default (props) => {
  const [tab, changeTab] = useState('1')

  const {
    pageTitle,
    engineerings,
    governmentClients,
    subcontractors,
  } = props.data.allContentfulPageClientsModal.nodes[0]

  const govClients = normalizeClientsArray(governmentClients)

  const engClients = normalizeClientsArray(engineerings)

  const subconClients = normalizeClientsArray(subcontractors)

  const allClients = [...govClients, ...engClients, ...subconClients]

  const [tabContent, changeTabContent] = useState(allClients)

  const onTabChange = (event) => {
    const changeTabTo =
      event && event.target && event.target.tabIndex ? `${event.target.tabIndex}` : '1'

    let changeTabContentTo = []

    if (changeTabTo === '2') {
      changeTabContentTo = govClients
    } else if (changeTabTo === '3') {
      changeTabContentTo = engClients
    } else if (changeTabTo === '4') {
      changeTabContentTo = subconClients
    } else {
      changeTabContentTo = allClients
    }

    changeTabContent(changeTabContentTo)
    changeTab(changeTabTo)
  }

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main className="mb-5">
        <PageHeader pageTitle={pageTitle} />
        <section className="mt-5 mb-5 container">
          <ul className="nav rounded-tabs mb-5 justify-content-center">
            <li className={`nav-item ${tab === '1' ? 'active' : ''}`} onClick={onTabChange}>
              <span className="nav-link" tabIndex="1">
                All clients
              </span>
            </li>
            <li className={`nav-item ${tab === '2' ? 'active' : ''}`} onClick={onTabChange}>
              <span className="nav-link" tabIndex="2">
                Government client
              </span>
            </li>
            <li className={`nav-item ${tab === '3' ? 'active' : ''}`} onClick={onTabChange}>
              <span className="nav-link" tabIndex="3">
                Engineering consultants
              </span>
            </li>
            <li className={`nav-item ${tab === '4' ? 'active' : ''}`} onClick={onTabChange}>
              <span className="nav-link" tabIndex="4">
                Subcontractors
              </span>
            </li>
          </ul>
          <Gallery photos={tabContent} margin={5} direction={'column'} />
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query allClientsData {
    allContentfulPageClientsModal {
      nodes {
        pageTitle
        governmentClients {
          title
          alt
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
        engineerings {
          title
          alt
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
        subcontractors {
          title
          alt
          image {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
      }
    }
  }
`
